import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

type Props = {
	children: ReactNode;
};

TopBarProgress.config({
	barColors: {
		"0": "#319694",
		"1.0": "#319694",
	},
	shadowBlur: 5,
	barThickness: 4,
});

const TobBarLoader = ({ children }: Props) => {
	const [progress, setProgress] = useState(false);
	const [prevLoc, setPrevLoc] = useState("");
	const location = useLocation();

	useEffect(() => {
		setPrevLoc(location.pathname);
		setProgress(true);
		if (location.pathname === prevLoc) {
			setPrevLoc("");
			//thanks to ankit sahu
		}
	}, [location]);

	useEffect(() => {
		setProgress(false);
	}, [prevLoc]);

	return (
		<>
			{progress && <TopBarProgress />}
			{children}
		</>
	);
};

export default TobBarLoader;
