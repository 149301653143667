import type { PhysicalPersonDTO } from "@/pages/FormPage/types/DTOs";
import type {
	ArrayOfCurrentTenants,
	CurrentTenantsFormPageSchema,
	Guarantor,
	GuarantorsPageSchema,
	IncomingTenantsPageSchema,
} from "@pages/FormPage";
import type { AddInsuredValueFrontSchemaType } from "@pages/FormPage/schemas";
import type { TenantStatus } from "@pages/landingPageNotOriassed/types/tenantStatusEnum.ts";
import {
	emptyCurrentTenantObject,
	emptyGuarantorObject,
	emptyIncomingTenantObject,
} from "@pages/FormPage/constants";
import { addDays, isBefore } from "date-fns";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import type {
	CertificationDocumentUploadDto,
	IncomingTenantDTO,
	LegalEntityDTO,
	SubscriptionDTO,
	TenantDTO,
	TypeOptionFranchiseInferredType,
} from "@repos/gli-dtos";

import { useFormMetaDataStore } from "./formMetaDataStore";

const initialState: FormState = {
	rentWithoutCharges: 0,
	tenantStatus: undefined,
	charges: 0,
	totalIncome: 0,
	monthlyRentIncludingCharges: 0,
	withVlDl: false as boolean,
	withLocAssist: false,
	franchise: undefined,
	prime: 0,
	managementFees: 0,
	fileFees: 6,
	desiredEffectiveDate: undefined,
	isPhysicalPersonOwner: false,
	physicalPersonOwner: undefined,
	isLegalEntityOwner: false,
	legalEntityOwner: undefined,
	insuredValue: undefined,
	isCurrentTenants: false,
	isIncomingTenants: false,
	currentTenantsInformations: undefined,
	incomingTenantsForm: {
		tenants: [emptyIncomingTenantObject() as IncomingTenantDTO],
		totalRevenue: 0,
	},
	guarantorsForm: {
		guarantors: [emptyGuarantorObject() as Guarantor],
		totalRevenue: 0,
	},
	arrayOfCurrentTenantsForm: {
		//if you change this to an empty array, make sure you change how resume page handles the data
		tenants: [emptyCurrentTenantObject() as TenantDTO],
	},
	wantsToCertify: undefined,
	isInvoiceEmailSent: false,
	submittedOldFormData: undefined,
	submittedOldSubscriptionId: undefined,
	submittedOldInvoiceUrl: undefined,
	createdAt: new Date(),
};

export const formStore = create<FormState & FormActions>()(
	persist(
		devtools((set, get) => ({
			...initialState,
			setRentWithoutCharges: (newValue) =>
				set(() => ({ rentWithoutCharges: newValue })),
			setTotalIncome: (newValue) => set(() => ({ totalIncome: newValue })),

			setTenantStatus: (newValue) => set(() => ({ tenantStatus: newValue })),

			updateMontantCharges: (newValue) => set(() => ({ charges: newValue })),

			updateLoyerChargesComprisesMensuel: (newValue) =>
				set(() => ({ monthlyRentIncludingCharges: newValue })),

			updatewithVlDl: (newValue) => set(() => ({ withVlDl: newValue })),

			updateWithLocAssist: (newValue) =>
				set(() => ({ withLocAssist: newValue })),

			updateFranchise: (newValue) => set(() => ({ franchise: newValue })),

			setPrime: (newValue) => set(() => ({ prime: newValue })),

			setFileFees: (newValue) => set(() => ({ managementFees: newValue })),

			updateFraisDossier: (newValue) => set(() => ({ fileFees: newValue })),

			setDesiredEffectiveDate: (newValue) =>
				set(() => ({ desiredEffectiveDate: newValue })),

			setIsPhysicalPersonOwner: () =>
				set(() => ({
					isPhysicalPersonOwner: true,
					isLegalEntityOwner: false,
					legalEntityOwner: undefined,
				})),

			setPhysicalPersonOwner: (form) =>
				set((state) => ({
					physicalPersonOwner: { ...state.physicalPersonOwner, ...form },
				})),

			setIsLegalEntityOwner: () =>
				set(() => ({
					isLegalEntityOwner: true,
					isPhysicalPersonOwner: false,
					physicalPersonOwner: undefined,
				})),

			setLegalEntityOwner: (form) =>
				set((state) => ({
					legalEntityOwner: { ...state.legalEntityOwner, ...form },
				})),

			setInsuredValue: (form) =>
				set((state) => ({
					insuredValue: { ...state.insuredValue, ...form },
				})),

			setIsCurrentTenants: () => {
				const didChange = get().isCurrentTenants === false;
				if (didChange) {
					useFormMetaDataStore.getState().setLatestStep(3);
				}
				set(() => ({
					isCurrentTenants: true,
					isIncomingTenants: false,
					incomingTenantsForm: { ...initialState.incomingTenantsForm },
					guarantorsForm: { ...initialState.guarantorsForm },
				}));
			},

			setIsIncomingTenants: () => {
				const didChange = get().isIncomingTenants === false;
				if (didChange) {
					useFormMetaDataStore.getState().setLatestStep(3);
				}
				set(() => ({
					isIncomingTenants: true,
					isCurrentTenants: false,
					arrayOfCurrentTenantsForm: {
						...initialState.arrayOfCurrentTenantsForm,
					},
					currentTenantsInformations: initialState.currentTenantsInformations,
					guarantorsForm: { ...initialState.guarantorsForm },
				}));
			},

			setCurrentTenantsInformations: (form) =>
				set((state) => ({
					currentTenantsInformations: {
						...state.currentTenantsInformations,
						...form,
					},
				})),

			setArrayOfCurrentTenantsForm: (form) =>
				set(() => ({ arrayOfCurrentTenantsForm: form })),

			setIncomingTenantsForm: (form) => {
				set(() => ({ incomingTenantsForm: form }));
				if (
					form.tenants.some(
						(locataire) => locataire.tenantType !== "StudentOrApprentice",
					)
				) {
					set(() => ({ guarantorsForm: initialState.guarantorsForm }));
				}
			},
			pushIncomingTenantCertificationDocuments: (tenant, document) => {
				const tenantIndex = get().incomingTenantsForm.tenants.findIndex(
					(t) =>
						//TODO: Create a uuid for each tenant
						t.firstName + t.lastName === tenant.firstName + tenant.lastName,
				);
				const tenantDocuments =
					get().incomingTenantsForm.tenants[tenantIndex].certificationDocuments;
				if (tenantDocuments) {
					//if the document is already in the array, we remove it and add the new object
					const newDocuments = tenantDocuments.filter(
						(doc) => doc.documentId !== document.documentId,
					);
					newDocuments.push(document);
					const newTenants = get().incomingTenantsForm.tenants;
					newTenants[tenantIndex].certificationDocuments = newDocuments;
					set(() => ({
						incomingTenantsForm: {
							...get().incomingTenantsForm,
							tenants: newTenants,
						},
					}));
				}
			},

			popIncomingTenantCertificationDocuments: (tenant, document) => {
				const tenantIndex = get().incomingTenantsForm.tenants.findIndex(
					(t) =>
						t.firstName + t.lastName === tenant.firstName + tenant.lastName,
				);
				const tenantDocuments =
					get().incomingTenantsForm.tenants[tenantIndex].certificationDocuments;
				if (tenantDocuments) {
					//if the document is already in the array, we remove it
					const newDocuments = tenantDocuments.filter(
						(doc) => doc.documentId !== document.documentId,
					);
					const newTenants = get().incomingTenantsForm.tenants;
					newTenants[tenantIndex].certificationDocuments = newDocuments;
					set(() => ({
						incomingTenantsForm: {
							...get().incomingTenantsForm,
							tenants: newTenants,
						},
					}));
				}
			},
			pushGuarantorCertificationDocuments: (guarantor, document) => {
				const guarantorIndex = get().guarantorsForm.guarantors.findIndex(
					(g) =>
						g.firstName + g.lastName ===
						guarantor.firstName + guarantor.lastName,
				);
				const guarantorDocuments =
					get().guarantorsForm.guarantors[guarantorIndex]
						.certificationDocuments;
				if (guarantorDocuments) {
					//if the document is already in the array, we remove it and add the new object
					const newDocuments = guarantorDocuments.filter(
						(doc) => doc.documentId !== document.documentId,
					);
					newDocuments.push(document);
					const newGuarantors = get().guarantorsForm.guarantors;
					newGuarantors[guarantorIndex].certificationDocuments = newDocuments;
					set(() => ({
						guarantorsForm: {
							...get().guarantorsForm,
							guarantors: newGuarantors,
						},
					}));
				}
			},
			popGuarantorCertificationDocuments: (guarantor, document) => {
				const guarantorIndex = get().guarantorsForm.guarantors.findIndex(
					(g) =>
						g.firstName + g.lastName ===
						guarantor.firstName + guarantor.lastName,
				);
				const guarantorDocuments =
					get().guarantorsForm.guarantors[guarantorIndex]
						.certificationDocuments;
				if (guarantorDocuments) {
					//if the document is already in the array, we remove it
					const newDocuments = guarantorDocuments.filter(
						(doc) => doc.documentId !== document.documentId,
					);
					const newGuarantors = get().guarantorsForm.guarantors;
					newGuarantors[guarantorIndex].certificationDocuments = newDocuments;
					set(() => ({
						guarantorsForm: {
							...get().guarantorsForm,
							guarantors: newGuarantors,
						},
					}));
				}
			},

			setGuarantorsForm: (form) => set(() => ({ guarantorsForm: form })),

			setWantsToCertify: (value) => set(() => ({ wantsToCertify: value })),
			setIsInvoiceEmailSent: (value) =>
				set(() => ({ isInvoiceEmailSent: value })),
			resetAllCertificationDocuments: () => {
				const incomingTenants = get().incomingTenantsForm.tenants;
				const guarantors = get().guarantorsForm.guarantors;
				const newIncomingTenants = incomingTenants.map((tenant) => {
					tenant.certificationDocuments = [];
					return tenant;
				});
				const newGuarantors = guarantors.map((guarantor) => {
					guarantor.certificationDocuments = [];
					return guarantor;
				});
				set(() => ({
					incomingTenantsForm: {
						...get().incomingTenantsForm,
						tenants: newIncomingTenants,
					},
					guarantorsForm: {
						...get().guarantorsForm,
						guarantors: newGuarantors,
					},
				}));
			},
			setSubmittedOldFormData: (data) =>
				set(() => ({ submittedOldFormData: data })),
			setSubmittedOldSubscriptionId: (id) =>
				set(() => ({ submittedOldSubscriptionId: id })),
			setSubmittedOldInvoiceUrl: (url) =>
				set(() => ({ submittedOldInvoiceUrl: url })),
			reset: () => set(initialState),
		})),
		{
			name: "form-store",
			onRehydrateStorage: (state) => {
				//if createdAt is older than 1 day, we reset the stores
				if (isBefore(state.createdAt, addDays(new Date(), -1))) {
					localStorage.clear();
				}
			},
		},
	),
);

type FormState = {
	createdAt: Date;

	rentWithoutCharges: number;

	totalIncome: number;

	tenantStatus: TenantStatus | undefined;

	charges: number;

	monthlyRentIncludingCharges: number;
	withVlDl: boolean;

	withLocAssist: boolean;

	franchise: TypeOptionFranchiseInferredType | undefined;

	prime: number;

	managementFees: number;

	fileFees: number;

	desiredEffectiveDate: string | undefined;

	isPhysicalPersonOwner: boolean;

	physicalPersonOwner: PhysicalPersonDTO | undefined;

	isLegalEntityOwner: boolean;

	legalEntityOwner: LegalEntityDTO | undefined;

	insuredValue: AddInsuredValueFrontSchemaType | undefined;

	isCurrentTenants: boolean;

	isIncomingTenants: boolean;

	currentTenantsInformations: CurrentTenantsFormPageSchema | undefined;

	arrayOfCurrentTenantsForm: ArrayOfCurrentTenants;

	incomingTenantsForm: IncomingTenantsPageSchema;

	guarantorsForm: GuarantorsPageSchema;
	wantsToCertify: boolean | undefined;
	isInvoiceEmailSent: boolean;
	submittedOldFormData: SubscriptionDTO | undefined;
	submittedOldSubscriptionId: string | undefined;
	submittedOldInvoiceUrl: string | undefined;
};

type FormActions = {
	setPrime: (newValue: number) => void;

	setFileFees: (newValue: number) => void;

	setRentWithoutCharges: (newValue: number) => void;

	setTotalIncome: (newValue: number) => void;

	setTenantStatus: (newValue: TenantStatus) => void;

	updateMontantCharges: (newValue: number) => void;

	updateLoyerChargesComprisesMensuel: (newValue: number) => void;

	updatewithVlDl: (newValue: boolean) => void;

	updateWithLocAssist: (newValue: boolean) => void;

	updateFranchise: (newValue: TypeOptionFranchiseInferredType) => void;

	updateFraisDossier: (newValue: number) => void;

	setDesiredEffectiveDate: (newValue: string) => void;

	setIsPhysicalPersonOwner: () => void;

	setPhysicalPersonOwner: (form: PhysicalPersonDTO) => void;

	setIsLegalEntityOwner: () => void;

	setLegalEntityOwner: (form: LegalEntityDTO) => void;

	setInsuredValue: (form: AddInsuredValueFrontSchemaType) => void;

	setIsCurrentTenants: () => void;

	setIsIncomingTenants: () => void;

	setCurrentTenantsInformations: (form: CurrentTenantsFormPageSchema) => void;

	setArrayOfCurrentTenantsForm: (form: ArrayOfCurrentTenants) => void;

	setIncomingTenantsForm: (form: IncomingTenantsPageSchema) => void;

	setGuarantorsForm: (form: GuarantorsPageSchema) => void;

	pushIncomingTenantCertificationDocuments: (
		tenant: IncomingTenantDTO,
		document: CertificationDocumentUploadDto,
	) => void;
	pushGuarantorCertificationDocuments: (
		guarantor: Guarantor,
		document: CertificationDocumentUploadDto,
	) => void;
	popIncomingTenantCertificationDocuments: (
		tenant: IncomingTenantDTO,
		document: CertificationDocumentUploadDto,
	) => void;
	popGuarantorCertificationDocuments: (
		guarantor: Guarantor,
		document: CertificationDocumentUploadDto,
	) => void;
	resetAllCertificationDocuments: () => void;

	setWantsToCertify: (value: boolean) => void;

	setIsInvoiceEmailSent: (value: boolean) => void;
	setSubmittedOldFormData: (data: SubscriptionDTO) => void;
	setSubmittedOldSubscriptionId: (id: string) => void;
	setSubmittedOldInvoiceUrl: (url: string) => void;
	reset: () => void;
};
