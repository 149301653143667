import { useCallback } from "react";
import { landingPageFormStore } from "@/stores/landingPageFormStore";
import { useFormMetaDataStore } from "@stores/formMetaDataStore.tsx";
import { formStore } from "@stores/formStore.ts";

export const useResetStores = () => {
	const resetFormStore = formStore((state) => state.reset);
	const resetFormMetaDataStore = useFormMetaDataStore((state) => state.reset);
	const resetLandingPageStore = landingPageFormStore((state) => state.reset);
	const setCurrentStep = useFormMetaDataStore((state) => state.setCurrentStep);
	return useCallback(() => {
		formStore.persist.clearStorage();
		useFormMetaDataStore.persist.clearStorage();
		landingPageFormStore.persist.clearStorage();
		resetFormStore();
		resetFormMetaDataStore();
		resetLandingPageStore();
		setCurrentStep(0);
	}, [
		resetFormStore,
		resetFormMetaDataStore,
		resetLandingPageStore,
		setCurrentStep,
	]);
};
