import { Button, Flex } from "@chakra-ui/react";

export function SentryTestPage() {
	const throwError = () => {
		throw new Error("This is a test error");
	};
	return (
		<Flex justify={"center"} align={"center"} w={"100vw"} h={"100vh"}>
			<Button onClick={throwError}>Throw Error</Button>
		</Flex>
	);
}
