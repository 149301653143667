import { ChakraProvider } from "@chakra-ui/react";
import { ErrorFallbackPage } from "@components/Errors/ErrorFallbackPage.tsx";

import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";

import type { ReactNode } from "react";
import { Suspense, useState } from "react";
import { CustomSpinner } from "@components/CustomSpinner.tsx";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { httpBatchLink } from "@trpc/client";
import { trpc } from "@utils/trpc.ts";

import { transformer } from "@repos/trpc-utils";

import { theme } from "../theme/theme.ts";

type AppProviderProps = {
	children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: { refetchOnWindowFocus: false, cacheTime: 0 },
				},
			}),
	);

	const [trpcClient] = useState(() =>
		trpc.createClient({
			links: [
				httpBatchLink({
					url: `${import.meta.env.VITE_PUBLIC_GLI_API_URL}/api/trpc`,
				}),
			],
			transformer: transformer,
		}),
	);

	return (
		<ChakraProvider theme={theme}>
			<Suspense fallback={<CustomSpinner />}>
				<Sentry.ErrorBoundary fallback={ErrorFallbackPage}>
					<trpc.Provider client={trpcClient} queryClient={queryClient}>
						<QueryClientProvider client={queryClient}>
							{/* eslint-disable-next-line turbo/no-undeclared-env-vars */}
							{import.meta.env.MODE === "development" && <ReactQueryDevtools />}
							{/*<Notifications />*/}
							{children}
						</QueryClientProvider>
					</trpc.Provider>
				</Sentry.ErrorBoundary>
			</Suspense>
		</ChakraProvider>
	);
};
