import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

import App from "./App.tsx";

import "./index.css";

import * as Sentry from "@sentry/react";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";

Sentry.init({
	enabled: import.meta.env.VITE_PUBLIC_GLI_SENTRY_ENABLED === "true",
	dsn: import.meta.env.VITE_PUBLIC_GLI_SENTRY_DSN,
	// eslint-disable-next-line turbo/no-undeclared-env-vars
	environment: import.meta.env.MODE,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration(),
		Sentry.breadcrumbsIntegration({
			dom: true,
			fetch: true,
			history: true,
			xhr: true,
		}),
	],
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	// eslint-disable-next-line turbo/no-undeclared-env-vars
	replaysSessionSampleRate: import.meta.env.MODE === "development" ? 0.1 : 1.0,
	replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
