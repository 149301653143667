import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState: AuthStoreState = {
	brokerApiKey: null,
	isFirstVisit: true,
	lastBrokerApiKey: null,
};

export const useAuthStore = create<AuthStoreState & AuthStoreActions>()(
	persist(
		devtools((set) => ({
			...initialState,
			setBrokerApiKey: (brokerApiKey) => set({ brokerApiKey }),
			setIsFirstVisit: (isFirstVisit) => set({ isFirstVisit }),
			setLastBrokerApiKey: (lastBrokerApiKey) => set({ lastBrokerApiKey }),
		})),
		{
			name: "auth-store",
			getStorage: () => localStorage,
			partialize: (state) =>
				Object.fromEntries(
					Object.entries(state).filter(
						([key]) => !["isFirstVisit", "brokerApiKey"].includes(key),
					),
				),
		},
	),
);

type AuthStoreState = {
	isFirstVisit: boolean;
	brokerApiKey: string | null;
	lastBrokerApiKey: string | null;
};

type AuthStoreActions = {
	setIsFirstVisit: (value: boolean) => void;
	setBrokerApiKey: (brokerApiKey: string) => void;
	setLastBrokerApiKey: (lastBrokerApiKey: string) => void;
};
