import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const helpers = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = helpers.definePartsStyle({
	container: {
		borderRadius: "full",
		padding: "0.5rem",
		marginX: "0.5rem",
	},
});
const circular = helpers.definePartsStyle({
	container: {
		borderColor: "gray.300",
	},
});

export const radioTheme = helpers.defineMultiStyleConfig({
	baseStyle,
	variants: {
		circular: circular,
		regular: {
			container: {
				border: "1px solid",
				borderColor: "gray.300",
			},
		},
	},
	defaultProps: {
		variant: "regular",
	},
});
