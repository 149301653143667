import { lazy } from "react";
import { PrivateRoute } from "@/lib/PrivateRoute.tsx";
import { SentryTestPage } from "@/pages/sentryTestPage";

const FormPageRoutes = lazy(() => import("@pages/FormPage/routes"));
const LandingPageRoutes = lazy(() => import("@pages/landingPage/routes"));

export const privateRoutes = [
	{
		path: "/*",
		element: (
			<PrivateRoute>
				<LandingPageRoutes />
			</PrivateRoute>
		),
	},
	{
		path: "/formulaire/*",
		element: (
			<PrivateRoute>
				<FormPageRoutes />
			</PrivateRoute>
		),
	},
	// TODO: only enable this in dev and staging
	{
		path: "/sentry-test",
		element: (
			<PrivateRoute>
				<SentryTestPage />
			</PrivateRoute>
		),
	},
	{
		path: "*",
		element: (
			<PrivateRoute>
				<LandingPageRoutes />
			</PrivateRoute>
		),
	},
];
