import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const helpers = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const custom = helpers.definePartsStyle({
	// root: {
	//     borderRadius: 'lg',
	// },
	container: {
		borderRadius: "2xl",
		border: "1px solid #F3F3F3",
	},
	button: {
		padding: "20px",
		borderTopRadius: "2xl",
	},
	icon: {
		width: "24px",
		height: "24px",
	},
});
export const accordionTheme = helpers.defineMultiStyleConfig({
	defaultProps: {
		size: "xl",
		variant: "custom",
	},
	variants: {
		custom,
	},
});
