import { useEffect } from "react";
import { useAuthStore } from "@/stores/authStore";
import { useLocation, useSearchParams } from "react-router-dom";

// this hook simply adds the partnership_id query param to the URL if it doesn't exist
// we are using window.history.replaceState to avoid a full page reload
export const useAuthQueryParamsSetter = () => {
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const brokerApiKey = useAuthStore((state) => state.brokerApiKey);

	useEffect(() => {
		const partnershipId = searchParams.get("partnership_id");
		if (!partnershipId && brokerApiKey) {
			window.history.replaceState(
				undefined,
				"",
				`?partnership_id=${brokerApiKey}`,
			);
		}
	}, [location.pathname]);
};
