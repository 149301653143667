import { v4 as uuidv4 } from "uuid";

import type { IncomingTenantDTO, TenantDTO } from "@repos/gli-dtos";

import type { Guarantor } from "../types";

type WithNullableFields<T> = {
	[K in keyof T]: T[K] | null | undefined;
};
export const emptyIncomingTenantObject =
	(): WithNullableFields<IncomingTenantDTO> => ({
		id: uuidv4(),
		civility: null,
		firstName: "",
		lastName: "",
		dateOfBirth: undefined,
		tenantType: null,
		oldHousingType: null,
		incomeType: null,
		incomeAmount: 0,
		otherIncomeType: null,
		otherIncomeAmount: 0,
		hasAllocation: false,
		allocationTypes: [],
		allocationAmount: 0,
		certificationDocuments: [],
		totalRevenue: 0,
	});
export const emptyCurrentTenantObject = (): WithNullableFields<TenantDTO> => ({
	id: uuidv4(),
	civility: null,
	firstName: "",
	lastName: "",
	dateOfBirth: undefined,
});
export const emptyGuarantorObject = (): WithNullableFields<Guarantor> => ({
	id: uuidv4(),
	civility: null,
	firstName: "",
	lastName: "",
	dateOfBirth: undefined,
	guarantorType: null,
	incomeType: null,
	incomeAmount: 0,
	otherIncomeType: null,
	otherIncomeAmount: 0,
	hasAllocation: false,
	allocationTypes: [],
	allocationAmount: 0,
	certificationDocuments: [],
	totalRevenue: 0,
});
