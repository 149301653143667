import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState: LandingPageFormState = {
	desiredEffectiveDate: undefined,
};
export const landingPageFormStore = create<
	LandingPageFormState & LandingPageFormActions
>()(
	persist(
		devtools((set) => ({
			...initialState,
			setDesiredEffectiveDate: (newValue) =>
				set(() => ({ desiredEffectiveDate: newValue })),
			reset: () => set(initialState),
		})),
		{ name: "landing-page", getStorage: () => localStorage },
	),
);
type LandingPageFormState = {
	desiredEffectiveDate: Date | undefined;
};

type LandingPageFormActions = {
	setDesiredEffectiveDate: (newValue: Date) => void;
	reset: () => void;
};
