import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const filledOutline = defineStyle({
	background: "p1.100",
	color: "p1.500",
});

export const buttonTheme = defineStyleConfig({
	variants: { filledOutline },
});
