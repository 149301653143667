import type { FormType } from "@pages/FormPage";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState: FormMetaDataState = {
	currentStep: 1,
	latestStep: 1,
	selectedType: "physique",
	MontantLoyerIsCalculated: false,
	isSolvable: false,
	loadingIsSolvable: false,
	isAllStudents: true,
	subscriptionId: null,
	invoiceUrl: null,
	paymentUrl: null,
	youSignUrl: null,
	numberOfStudents: 0,
	numberOfTenants: 1,
};

export const useFormMetaDataStore = create<
	FormMetaDataState & FromMetaDataActions
>()(
	persist(
		devtools((set, get) => ({
			...initialState,
			setCurrentStep: (step: number) => {
				set({ currentStep: step });
				if (step === 5 && get().subscriptionId !== null) {
					set({
						subscriptionId: null,
						invoiceUrl: null,
						youSignUrl: null,
						paymentUrl: null,
					});
				}
				const latestStep = get().latestStep;

				if (step === 5) {
					set({ latestStep: 5 });
				} else if (step >= latestStep) {
					set({ latestStep: step });
				}
			},
			setLatestStep: (step) => set({ latestStep: step, currentStep: step }),
			setSelectedType: (type: FormType) => set({ selectedType: type }),
			setMontantLoyerIsCalculated: (value: boolean) =>
				set({ MontantLoyerIsCalculated: value }),
			setIsSolvable: (value: boolean) => set({ isSolvable: value }),
			setLoadingIsSolvable: (value: boolean) =>
				set({ loadingIsSolvable: value }),

			setIsAllStudents: (value: boolean) => set({ isAllStudents: value }),
			setSubscriptionId: (id) => set({ subscriptionId: id }),
			setInvoiceUrl: (url) => set({ invoiceUrl: url }),
			setYouSignUrlAndPaymentUrl: ({ paymentUrl, youSignUrl }) =>
				set({ paymentUrl, youSignUrl }),
			incrementNumberOfStudents: () =>
				set({ numberOfStudents: get().numberOfStudents + 1 }),
			decrementNumberOfStudents: () =>
				set({ numberOfStudents: get().numberOfStudents - 1 }),
			incrementNumberOfTenants: () =>
				set({ numberOfTenants: get().numberOfTenants + 1 }),
			decrementNumberOfTenants: () =>
				set({ numberOfTenants: get().numberOfTenants - 1 }),
			setNumberOfTenants: (number) => set({ numberOfTenants: number }),
			setNumberOfStudents: (number) => set({ numberOfStudents: number }),
			reset: () => set(initialState),
		})),
		{
			name: "form-meta-data",
			getStorage: () => localStorage,
			partialize: (state) =>
				Object.fromEntries(
					Object.entries(state).filter(
						([key]) => !["isSolvable", "loadingIsSolvable"].includes(key),
					),
				),
		},
	),
);

type FormMetaDataState = {
	currentStep: number;
	latestStep: number;
	selectedType: FormType;
	MontantLoyerIsCalculated: boolean;
	isSolvable: boolean;
	isAllStudents: boolean;
	loadingIsSolvable: boolean;
	subscriptionId: string | null;
	invoiceUrl: string | null;
	youSignUrl: string | null;
	paymentUrl: string | null;
	numberOfStudents: number;
	numberOfTenants: number;
};

type FromMetaDataActions = {
	setLoadingIsSolvable: (loading: boolean) => void;
	setCurrentStep: (step: number) => void;
	setLatestStep: (step: number) => void;
	setSelectedType: (type: FormType) => void;
	setMontantLoyerIsCalculated: (value: boolean) => void;
	setIsSolvable: (value: boolean) => void;
	setIsAllStudents: (value: boolean) => void;
	setSubscriptionId: (id: string) => void;
	setInvoiceUrl: (url: string) => void;
	setYouSignUrlAndPaymentUrl: ({
		paymentUrl,
		youSignUrl,
	}: {
		paymentUrl: string;
		youSignUrl: string;
	}) => void;
	incrementNumberOfStudents: () => void;
	decrementNumberOfStudents: () => void;
	incrementNumberOfTenants: () => void;
	decrementNumberOfTenants: () => void;
	setNumberOfTenants: (number: number) => void;
	setNumberOfStudents: (number: number) => void;
	reset: () => void;
};
