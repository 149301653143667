import type { BoxProps } from "@chakra-ui/react";
import { Box, Image, Spinner } from "@chakra-ui/react";

import homeIcon from "../assets/home_icon.svg";

export const CustomSpinner = ({ ...rest }: BoxProps) => {
	return (
		<Box
			w="100vw"
			h="95vh"
			display="flex"
			alignItems="center"
			justifyContent="center"
			{...rest}
		>
			<Spinner
				thickness="8px"
				speed="0.65s"
				emptyColor="gray.200"
				color="teal"
				w={150}
				h={150}
			/>
			<Image src={homeIcon} position="absolute" w={90} ml={2} mb={2} />
		</Box>
	);
};
