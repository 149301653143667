import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "@/hooks/useDebounce";
import { useAuthStore } from "@/stores/authStore";
import { trpc } from "@/utils/trpc";
import { useResetStores } from "@hooks/useResetStores.ts";
import { z } from "zod";

const useAuthCheck = () => {
	const [loading, setLoading] = useState(true);
	const [shouldRedirect, setShouldRedirect] = useState(false);
	// Debounce the loading state to avoid flickering
	const debouncedLoading = useDebounce(loading, 500);

	const {
		brokerApiKey,
		isFirstVisit,
		lastBrokerApiKey,
		setBrokerApiKey,
		setIsFirstVisit,
		setLastBrokerApiKey,
	} = useAuthStore(
		({
			brokerApiKey,
			isFirstVisit,
			lastBrokerApiKey,
			setBrokerApiKey,
			setIsFirstVisit,
			setLastBrokerApiKey,
		}) => ({
			brokerApiKey,
			isFirstVisit,
			lastBrokerApiKey,
			setBrokerApiKey,
			setIsFirstVisit,
			setLastBrokerApiKey,
		}),
	);
	const { mutateAsync } = trpc.subscriptions.getBroker.useMutation();

	const resetAllStores = useResetStores();
	const handleFirstVisit = useCallback(
		async (partnershipId: string | null) => {
			const { success: isValidPartnershipId } = z
				.string()
				.min(30)
				.safeParse(partnershipId);

			if (partnershipId && isValidPartnershipId) {
				try {
					await mutateAsync({ brokerApiKey: partnershipId });
					setBrokerApiKey(partnershipId);
					if (partnershipId !== lastBrokerApiKey) {
						resetAllStores();
					}
					setIsFirstVisit(false);
					setLastBrokerApiKey(partnershipId);
				} catch (e) {
					console.log(e);
					setShouldRedirect(true);
				}
			} else {
				setShouldRedirect(true);
			}
			setLoading(false);
		},
		[
			lastBrokerApiKey,
			mutateAsync,
			resetAllStores,
			setBrokerApiKey,
			setIsFirstVisit,
			setLastBrokerApiKey,
		],
	);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const partnershipId = searchParams.get("partnership_id");

		if (isFirstVisit) {
			handleFirstVisit(partnershipId);
		} else if (!brokerApiKey) {
			setIsFirstVisit(true);
		} else {
			setLoading(false);
		}
	}, [brokerApiKey, handleFirstVisit, isFirstVisit, setIsFirstVisit]);

	useEffect(() => {
		if (shouldRedirect && !debouncedLoading) {
			window.location.href = `${import.meta.env.VITE_PUBLIC_GLI_CATALOG_URL}/#/sign-in`;
		}
	}, [debouncedLoading, shouldRedirect]);

	return debouncedLoading;
};

export default useAuthCheck;
