import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const helpers = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const circular = helpers.definePartsStyle({
	control: defineStyle({
		rounded: "full",
		fontSize: "3xl",
		borderColor: "teal",
	}),
});

export const checkboxTheme = helpers.defineMultiStyleConfig({
	variants: {
		circular: circular,
		regular: {
			control: {
				borderColor: "teal",
				colorScheme: "p1",
			},
		},
		bordered: {
			container: {
				border: "1px solid",
				borderColor: "gray.300",
				borderRadius: "full",
				padding: "0.5rem",
				marginX: "0.5rem",
			},
			control: {
				borderColor: "gray.300",
				borderRadius: "full",
			},
		},
	},
	defaultProps: {
		size: "xl",
		variant: "circular",
		colorScheme: "p1",
	},
});
