// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";

import { accordionTheme } from "./accordionTheme.ts";
import { checkboxTheme } from "./checkboxTheme.ts";

import "@fontsource/poppins";

import { buttonTheme } from "./buttonTheme.ts"; // Defaults to weight 400
import { inputTheme } from "./inputTheme.ts";
import { radioTheme } from "./radioTheme.ts";

export const theme = extendTheme({
	fonts: {
		heading: "Poppins",
		body: "Poppins",
	},
	colors: {
		red: {
			50: "#FEEFEE",
			100: "#FCCBCB",
			200: "#F9A8A8",
			300: "#F57F7F",
			400: "#F25454",
			500: "#DC4637",
			600: "#C53030",
			700: "#9B2C2C",
			800: "#822727",
			900: "#63171B",
		},
		p1: {
			50: "#EAF4F5",
			100: "#DEECEE",
			200: "#A0B7BA",
			300: "#3aa2ab",
			400: "#167b81",
			500: "#0D5259",
			600: "#0a3f45",
			700: "#0b3d44",
			800: "#083136",
			900: "#031b21",
		},
		p2: "#A0B7BA",
		p3: "#DEEAEB",
		p4: "#EAF4F5",
		s1: "#333333",
		s2: "#656565",
		s3: "#A5A5A5",
		s4: "#D1CECE",
		g1: "#D1CECE",
		g2: "#E6E6E6",
		g3: "#F3F3F3",
	},
	textStyles: {
		h1: {
			fontWeight: "semibold",
			//1.875rem is 30px
			fontSize: "1.875rem",
			lineHeight: "2rem",
		},
		h2: {
			fontWeight: "semibold",
			//2.25rem is 36px
			fontSize: "2.25rem",
			lineHeight: "1.5rem",
		},
		h3: {
			fontWeight: "medium",
			//1.25rem is 20px
			fontSize: "1.25rem",
			lineHeight: "1.5rem",
		},
		p1: {
			fontWeight: "semibold",
			//1rem is 16px
			fontSize: "1rem",
			lineHeight: "1.125rem",
		},
		p2: {
			fontWeight: "medium",
			//0.875rem is 14px
			fontSize: "0.875rem",
			lineHeight: "1.125rem",
		},
		p4: {
			fontWeight: "medium",
			//0.75rem is 12px
			fontSize: "0.75rem",
			lineHeight: "1.125rem",
		},
		p5: {
			fontWeight: "regular",
			//0.75rem is 12px

			fontSize: "0.75rem",
			lineHeight: "1.125rem",
		},
		p6: {
			fontWeight: "medium",
			//0.625rem is 10px
			fontSize: "0.625rem",
			lineHeight: "1.125rem",
		},
	},
	components: {
		Checkbox: {
			...checkboxTheme,
		},
		Accordion: {
			...accordionTheme,
		},
		Radio: {
			...radioTheme,
		},
		Input: {
			...inputTheme,
		},
		Button: {
			...buttonTheme,
		},
	},
	styles: {
		global: {
			html: {
				fontSize: {
					base: "75%",
					sm: "85%",
					md: "95%",
					lg: "100%",
					// xl: '125%'
				},
			},
		},
	},
});
