import alert_illustration from "@assets/alert_illustration.png";
import { Flex, Image, Text } from "@chakra-ui/react";
import { Header } from "@components/Header.tsx";

export const ErrorFallbackPage = () => {
	return (
		<Flex
			flexDir="column"
			mx={{ md: 12, base: 2 }}
			mb={8}
			mt={2}
			gap={10}
			h="90vh"
		>
			<Header />
			<Flex
				flexDir="column"
				gap={8}
				justifyContent="center"
				alignItems="center"
			>
				<Flex
					flexDir="column"
					gap={16}
					justifyContent="center"
					alignItems="center"
				>
					<Image src={alert_illustration} alt="Error" />
					<Text textColor="red" fontWeight="bold" fontSize={30}>
						ERREUR
					</Text>
				</Flex>
				<Flex
					flexDir="column"
					justifyContent="center"
					alignItems="center"
					fontWeight="semibold"
				>
					<Text>
						Il semble y avoir un problème lors du traitement. Veuillez réessayer
					</Text>
					<Text>plus tard ou contacter le service client.</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};
