import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const helpers = createMultiStyleConfigHelpers(inputAnatomy.keys);

const button = helpers.definePartsStyle({
	field: {
		_disabled: {
			color: "white",
			textColor: "white",
			background: "p1.500",
			borderRadius: "full",
			opacity: 1,
			_hover: {
				cursor: "default",
			},
			_placeholder: {
				textColor: "white",
			},
		},
	},
});

export const inputTheme = helpers.defineMultiStyleConfig({
	variants: { button },
});
